<template>
  <!-- 企业-资料&&认证信息 -->
  <div class="contentBox">
    <vue-element-loading
      :active="loadingActive"
      :is-full-screen="true"
      color="#2674fc"
      background-color="rgba(0,0,0,.3)"
      text="正在上传，请稍后！"
    />
    <section>
      <el-card class="box-card">
        <div slot="header">
          <span class="cardTitle">{{ editTitle }}</span>
        </div>
        <div class="formBox">
          <el-form
            class="comForm"
            ref="comInfo"
            :model="comInfo"
            label-width="80px"
          >
            <el-form-item
              label="企业头像"
              class="avatarBox"
              :rules="[
                {
                  required: true,
                  message: '请设置企业头像',
                  trigger: 'change',
                },
              ]"
            >
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :headers="headers"
                :data="avatarData"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar mini" />
                <i v-else class="el-icon-plus avatar-uploader-icon mini"></i>
              </el-upload>
              <div class="avatarMsg">
                <p>支持jpg、png格式的图片，大小限制1MB内</p>
              </div>
            </el-form-item>
            <el-row :gutter="16">
              <el-col :span="12">
                <el-form-item
                  label="企业名称"
                  prop="com_name"
                  :rules="[
                    {
                      required: true,
                      message: '请输入企业名称',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="comInfo.com_name"
                    :disabled="!haveDataBtn"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="对外名称"
                  prop="com_external_name"
                  :rules="[
                    {
                      required: true,
                      message: '请输入企业对外名称',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input v-model="comInfo.com_external_name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="企业性质"
                  prop="com_nature"
                  :rules="[
                    {
                      required: true,
                      message: '请选择企业性质',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select v-model="comInfo.com_nature" style="width:100%">
                    <el-option
                      v-for="(item, index) in com_natureList"
                      :key="index"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="企业规模"
                  prop="com_size"
                  :rules="[
                    {
                      required: true,
                      message: '请选择企业性质',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select v-model="comInfo.com_size" style="width:100%">
                    <el-option
                      v-for="(item, index) in com_sizeList"
                      :key="index"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="公司网址" prop="com_website">
              <el-input v-model="comInfo.com_website"></el-input>
            </el-form-item>
            <el-form-item
              label="营业执照"
              class="licenseBox"
              :rules="[
                {
                  required: true,
                  message: '请上传营业执照',
                  trigger: 'change',
                },
              ]"
            >
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :headers="headers"
                :data="licenseData"
                :show-file-list="false"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload2"
                :disabled="!haveDataBtn"
              >
                <img
                  v-if="licenseUrl"
                  :src="licenseUrl"
                  class="avatar langer"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon langer"></i>
              </el-upload>
              <div class="licenseMsg">
                <p>温馨提示：</p>
                <p>
                  1、支持jpg、png格式的图片，最大不能超过 3MB，最小不低于1MB；
                </p>
                <p>
                  2、营业执照扫描件（复印件）上的名称必须和填写的公司名称一致；
                </p>
                <p>3、上传清晰的营业执照更容易通过审核。</p>
                <p>4、请不要提交虚假信息，我们对用户提交的信息严格保密。</p>
              </div>
            </el-form-item>
            <el-form-item
              label="业务范围"
              :rules="[
                {
                  required: true,
                  message: '请选择业务范围',
                  trigger: 'change',
                },
              ]"
            >
              <el-checkbox-group v-model="busList" @change="changeBusList">
                <el-checkbox label="兼职服务"></el-checkbox>
                <el-checkbox label="培训"></el-checkbox>
                <el-checkbox label="资质代办"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              label="代办范围"
              v-if="goodAtShow"
              :rules="[
                {
                  required: true,
                  message: '请选择代办范围',
                  trigger: 'change',
                },
              ]"
            >
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <el-checkbox-group
                v-model="goodAtList"
                @change="handleCheckedCitiesChange"
              >
                <el-checkbox
                  v-for="(item, index) in goodAtSel"
                  :key="index"
                  :label="item"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              label="公司介绍"
              prop="com_synopsis"
              :rules="[
                {
                  required: true,
                  message: '请输入公司介绍',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                :rows="3"
                v-model="comInfo.com_synopsis"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业固话" prop="com_fixed">
              <el-input v-model="comInfo.com_fixed"></el-input>
            </el-form-item>
            <el-form-item
              label="所在地区"
              prop="com_address"
              :rules="[
                {
                  required: true,
                  message: '请输入所在地区和街道门牌号',
                  trigger: 'blur',
                },
              ]"
            >
              <el-cascader
                size="large"
                :options="comcity"
                v-model="selectedOptions"
                @change="setUseInfoSocial"
                style="width: 48.5%;"
                :placeholder="addresInfo"
              >
              </el-cascader>
              —
              <el-input
                v-model="comInfo.com_address"
                style="width:48.5%"
                placeholder="街道门牌号"
              ></el-input>
            </el-form-item>
            <el-form-item class="text-center">
              <el-button
                type="primary"
                class="radius-25"
                style="width: 280px"
                v-prevent-repeat-click
                @click="infoPost"
              >
                提交
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import VueElementLoading from "vue-element-loading";
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  components: {
    VueElementLoading,
  },
  data() {
    return {
      comInfo: {},
      selectedOptions: [],
      postFile: [],
      imageUrl: "",
      licenseUrl: "",
      uploadUrl: "",
      comcity: provinceAndCityData,
      busList: [],
      goodAtList: [],
      goodAtShow: false,
      checkAll: false,
      isIndeterminate: false,
      com_natureList: [
        { label: "外商独资" },
        { label: "中外合资" },
        { label: "国有企业" },
        { label: "外企代表处" },
        { label: "政府机关" },
        { label: "事业单位" },
        { label: "股份制企业" },
        { label: "私营/民营企业" },
        { label: "混合所有制" },
        { label: "500强企业" },
        { label: "其他" },
      ],
      com_sizeList: [
        { label: "1-49人" },
        { label: "50-99人" },
        { label: "100-499人" },
        { label: "500-999人" },
        { label: "1000人以上" },
      ],
      goodAtSel: [
        "施工总承包",
        "施工专业承包",
        "劳务资质",
        "监理",
        "勘察设计",
        "招标代理",
        "造价咨询",
        "质量检测",
        "审图机构",
        "园林绿化",
        "设计施工一体化",
        "其它",
      ],
      avatarData: {},
      licenseData: {},
      addresInfo: "",
      loadingActive: false,
      haveDataBtn: false,
      editTitle: "完善信息",
      imgToText: "",
    };
  },
  computed: {
    ...mapState(["uploadSrc", "userInfo", "Authorization", "comId"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
    headers() {
      return {
        Accept: "application/json",
        token: this.Authorization,
      };
    },
  },
  watch: {},
  methods: {
    ...mapMutations(["changecomId"]),
    getUploadUrl() {
      this.uploadUrl = this.uploadSrc;
      this.avatarData.fi_objid = this.userInfo.uid;
      this.avatarData.fi_objcat = "at";
      this.avatarData.act = "upload";
      this.licenseData.fi_objid = this.userInfo.uid;
      this.licenseData.fi_objcat = "com";
      this.licenseData.act = "upload";
      console.log(this.userInfo);
    },
    // 头像
    handleAvatarSuccess(res, file) {
      if (res.resultCode === 0) {
        this.$message.warning("图片上传失败！");
        return;
      }
      this.imageUrl = URL.createObjectURL(file.raw);
      this.comInfo.com_file_id = res.resultData.file_id;
    },
    beforeAvatarUpload(file) {
      const isJP = file.type === "image/jpeg" || file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isJP) {
        this.$message.error("上传图片只能是 JPG 格式或 PNG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isJP && isLt1M;
    },
    // 营业执照
    handleAvatarSuccess2(res, file) {
      if (res.resultCode === 0) {
        this.$message.warning("图片上传失败！");
        return;
      }
      this.licenseUrl = URL.createObjectURL(file.raw);
      console.log(res);
      this.comInfo.com_license = res.resultData.file_id;
      let uid = this.userInfo.uid;
      this.$http
        .get(
          "index/file_upload/upfile?fi_objid=" +
            uid +
            "&fi_objcat=com&act=download&fi_id=" +
            this.comInfo.com_license
        )
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.comInfo.url = res.data.resultData.url;
            this.loadingActive = false;
            // 营业执照转文字
            this.$http
              .post("company/index/discern", { url: this.comInfo.url })
              .then((res) => {
                if (res.data.resultCode === 1) {
                  this.imgToText = res.data.resultData.name;
                }
              });
          }
        });
    },
    beforeAvatarUpload2(file) {
      const isJP = file.type === "image/jpeg" || file.type === "image/png";
      const isLt3M = file.size / 1024 / 1024 < 3;
      const isLt1M = file.size / 1024 / 1024 > 1;
      this.loadingActive = true;
      if (!isJP) {
        this.$message.error("上传图片只能是 JPG 格式或 PNG 格式!");
        this.loadingActive = false;
      }
      if (!isLt1M || !isLt3M) {
        this.$message.error("上传图片最大不能超过 3MB，最小不低于1MB!");
        this.loadingActive = false;
      }
      return isJP && isLt1M;
    },
    getBase64(file) {
      return new Promise(function(resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file.raw);
        reader.onload = function() {
          imgResult = reader.result;
        };
        reader.onerror = function(error) {
          reject(error);
        };
        reader.onloadend = function() {
          resolve(imgResult);
        };
      });
    },
    // 用户信息编辑社保地区
    setUseInfoSocial() {
      this.comInfo.com_province = CodeToText[this.selectedOptions[0]];
      this.comInfo.com_city = CodeToText[this.selectedOptions[1]];
    },
    //  业务范围
    changeBusList(e) {
      if (e.indexOf("资质代办") > -1) {
        this.goodAtShow = true;
      } else {
        this.goodAtShow = false;
        this.goodAtList = [];
      }
    },
    handleCheckAllChange(val) {
      this.goodAtList = val ? this.goodAtSel : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.goodAtSel.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.goodAtSel.length;
    },
    // 提交
    infoPost() {
      if (this.goodAtShow && this.goodAtList.length == 0) {
        this.$message.warning("代办范围不能为空!");
        return;
      }
      let comName = this.comInfo.com_name.replace("（", "");
      comName = comName.replace("）", "");
      comName = comName.replace("(", "");
      comName = comName.replace(")", "");
      if (comName != this.imgToText) {
        this.$message.warning("所填写的企业名称与营业执照上的企业名称不符！");
        return;
      }
      this.comInfo.com_business = this.busList.toString();
      this.comInfo.com_commission = this.goodAtList.toString();
      this.$http.post("company/index/attestation", this.comInfo).then((res) => {
        if (res.data.resultCode === 1) {
          let comid = res.data.resultData.com_id;
          this.changecomId({
            comId: comid,
          });
          this.$message.success("编辑信息已保存，2秒后跳转企业首页！");
          setTimeout(() => {
            this.$router.push("/comPage/index");
          }, 1600);
        } else if (res.data.resultCode === 7) {
          this.$http
            .post("company/index/associated", this.comInfo)
            .then((res) => {
              if (res.data.resultCode === 1) {
                let comid = res.data.resultData.com_id;
                this.changecomId({
                  comId: comid,
                });
                this.$message.success(
                  "您填写的企业已存在，已关联该企业，2秒后跳转企业首页！"
                );
                setTimeout(() => {
                  this.$router.push("/comPage/index");
                }, 1600);
              }
            });
        }
      });
    },
    // 获取企业已完善的信息
    getComInfo() {
      if (this.comId == 0) {
        this.$http.post("company/index/getcompanyinfo").then((res) => {
          if (res.data.resultCode === 1) {
            if (res.data.resultData.length < 1) {
              this.haveDataBtn = true;
              this.editTitle = "完善信息";
              return;
            }
            this.haveDataBtn = false;
            this.editTitle = "公司资料";
            this.comInfo = res.data.resultData;
            let data = res.data.resultData;
            this.addresInfo = data.com_province + "-" + data.com_city;
            this.comInfo.com_id = data.id;
            this.comInfo.url = data.com_license_url;
            if (data.com_file_url != "") {
              this.imageUrl = data.com_file_url;
            }
            if (data.com_license_url != "") {
              this.licenseUrl = data.com_license_url;
            }
            if (data.com_business != "") {
              this.busList = data.com_business.split(",");
            }
            if (data.com_commission != "") {
              this.goodAtList = data.com_commission.split(",");
              this.goodAtShow = true;
            }
          }
        });
      } else {
        this.$http
          .post("company/index/getcompanyinfo", { u_company: this.comId })
          .then((res) => {
            if (res.data.resultCode === 1) {
              if (res.data.resultData.length < 1) {
                this.haveDataBtn = true;
                this.editTitle = "完善信息";
                return;
              }
              this.haveDataBtn = false;
              this.editTitle = "公司资料";
              this.comInfo = res.data.resultData;
              let data = res.data.resultData;
              this.addresInfo = data.com_province + "-" + data.com_city;
              this.comInfo.com_id = data.id;
              this.comInfo.url = data.com_license_url;
              if (data.com_file_url != "") {
                this.imageUrl = data.com_file_url;
              }
              if (data.com_license_url != "") {
                this.licenseUrl = data.com_license_url;
              }
              if (data.com_business != "") {
                this.busList = data.com_business.split(",");
              }
              if (data.com_commission != "") {
                this.goodAtList = data.com_commission.split(",");
                this.goodAtShow = true;
              }
            }
          });
      }
    },
  },
  mounted() {
    this.getUploadUrl();
    this.getComInfo();
  },
};
</script>
<style scoped>
.comForm {
  margin: 0 100px;
}
.cardTitle {
  font-size: 17px;
  font-weight: bold;
  color: #2674fc;
}
.licenseBox {
  height: 200px;
  position: relative;
}
.licenseMsg {
  width: 445px;
  position: absolute;
  top: 0;
  left: 240px;
  text-align: left;
}
.licenseMsg p:first-child,
.avatarMsg p:first-child {
  color: #ff0000;
}
.licenseMsg p {
  line-height: 30px;
}
.avatarBox {
  position: relative;
}
.avatarMsg {
  width: 330px;
  position: absolute;
  top: 0;
  left: 80px;
}
</style>
